import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

export default function Template({ data }) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  return (
    <>
      <SEO
        title={frontmatter.title}
        description={
          frontmatter.metaDescription || frontmatter.excerpt || "nothin’"
        }
        image={frontmatter.thumbnail || "/assets/default.jpg"}
        pathname={frontmatter.slug}
        date={frontmatter.date}
        article
      />
      <Layout pageTitle={frontmatter.title}>
        <div className="blog-post-container">
          <article className="post">
            <div
              className="post-thumbnail"
              style={{
                backgroundImage: `url(${
                  frontmatter.thumbnail || "/assets/default.jpg"
                })`,
              }}
            >
              <h1 className="post-title">{frontmatter.title}</h1>
              <div className="post-meta">{frontmatter.date}</div>
            </div>

            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </article>
        </div>
      </Layout>
    </>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        date(formatString: "MMMM DD, YYYY")
        title
        thumbnail
        metaDescription
      }
    }
  }
`;
